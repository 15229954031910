<template>
  <div class="row mx-0 mt-4 justify-content-center">
    <div class="col-10 col-md-8 mb-2">
      <div class="row mx-0 mt-4 justify-content-center">
        <div class="col-11 p-0 mb-3">
          <TextField type="date" color="gold" placeholder="Start Date" label="Start Date" v-model="startDateValue" id="start" />
        </div>
        <div class="col-11 p-0 mb-3 text-center">
          <TextField type="date" color="gold" placeholder="End Date" label="End Date" v-model="endDateValue" id="end" v-if="!untilCancelledValue" class="text-start" />
          <div class="row mx-0 mt-3 justify-content-center">
            <div class="col-auto p-0">
              <div class="form-check medium">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="checkbox" value="cancelled" id="cancelled" v-model="untilCancelledValue">
                  </div>
                  <div class="col-auto p-0">
                    <label class="form-check-label" for="cancelled">
                      Until further notice
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 p-0 mb-3 text-center medium">
          Payments will be processed each
          <span v-if="frequency === 'DAILY'">day starting on the selected start date</span>
          <span v-else-if="frequency === 'WEEKLY'">week starting on the selected start date</span>
          <span v-else-if="frequency === 'MONTHLY'">month on the same date as the selected start date, starting on the selected start date </span>
          <span v-if="untilCancelled"> until cancelled</span>
          <span v-else> until the selected end date</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue'))
  },
  props: ['startDate', 'endDate', 'untilCancelled', 'frequency'],
  emits: ['update:startDate', 'update:endDate', 'update:untilCancelled'],
  name: 'Recurring PreferencesSection',
  computed: {
    startDateValue: {
      get () {
        return this.startDate
      },
      set (val) {
        this.$emit('update:startDate', val)
      }
    },
    endDateValue: {
      get () {
        return this.endDate
      },
      set (val) {
        this.$emit('update:endDate', val)
      }
    },
    untilCancelledValue: {
      get () {
        return this.untilCancelled
      },
      set (val) {
        this.$emit('update:untilCancelled', val)
      }
    }
  },
  methods: {
    setFrequency (val) {
      this.frequencyValue = val
    }
  }
}
</script>
